let host = '';//'https://outletsmallapi.bxncc.cc'; //'http://192.168.33.128:8000';
let imageHost = 'https://bxn-qy-workspace.oss-cn-hangzhou.aliyuncs.com';
const api = {
    host: host,
    imageHost: imageHost,
    // login: host + '/auth/ompLogin',
    getStoreInfo: host + '/consumer/v1/wx/cp/place/getStoreInfo',
    // getPlaceActionListByBrand: host + '/consumer/v1/wx/cp/place/action/getPlaceActionListByBrand',
    getPlaceActionListByBrandAndStoreId:host + '/consumer/v1/wx/cp/place/action/getPlaceActionListByBrandAndStoreId',
    uploadImage: host + '/consumer/v1/wx/cp/place/addPlaceImage',
    removeImage: host + '/consumer/v1/wx/cp/place/removePlaceImage',
    placeList: host + '/consumer/v1/wx/cp/place/getPlace',
    updateLongLatitude: host + '/consumer/v1/wx/cp/place/modifyLongLatitude',
    getJsSdkConfig: host + '/consumer/v1/wx/cp/getJsSdkConfig',
    // uploadImage: host + '/consumer/v1/wx/cp/place/placeImage',
    // removeImage: host + '/consumer/v1/wx/cp/place/delPlaceImage',
    // placeList: host + '/consumer/v1/wx/cp/place/actionPlace',
    // updateLongLatitude: host + '/consumer/v1/wx/cp/place/updateLongLatitude',
    mapSearch: host + '/consumer/v1/map/search',
    getUserInfo: host + '/consumer/v1/wx/cp/getUserInfo',
    getAdjustment: host + '/system/store/place_assess/getPlaceAssessItemListByAssessLevel',
    getAdjustmentNum: host + '/consumer/v1/wx/cp/placeassess/getPlaceAssessItemNumberByStoreIdAndActionId',
    modifyPlaceAssessItemImage: host + '/consumer/v1/wx/cp/placeassess/modifyPlaceAssessItemImage',
    getPlaceAssessItemAreaListByAssessId: host + '/system/store/place_assess/getPlaceAssessItemAreaListByAssessId',
    getPlaceAssessById: host + '/system/store/place_assess/getPlaceAssessById',
}

export default api;